.app {
    font-family: 'Montserrat';

    @media (min-width: 1024px) {
        width: 70%;
        text-align: center;
        margin: 0 auto;
        line-height: 2rem;
      }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Amatic SC', cursive;
}




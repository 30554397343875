.field {
   
    &__input {
        height: 2.3rem;
        background-color: #bdb026;
        border: none; 
        padding: .5rem;
        border-radius: 5px;
        margin-bottom: 1rem;
        width: 70%;
        &::placeholder {
            color: white;
        }


    }
}
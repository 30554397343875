.intro__responsive {
    @media (min-width: 1024px) {
        display: none;
    }
}

.intro {
    box-sizing: border-box;
    

    &__hidden {
        display: none;
    }
   

    &__title {
        font-size: 2.5em;
        font-weight: bold;
        color: #bdb026;
        text-align: center;
        margin-bottom: 1rem;

        &__open {
             display: none;
        }
       }
    
       &__firstsection {
           
        &__image {
            display: none;

            &__open {
               width: 31%;
                border-radius: 50%;
                transition: 2s;
                float: right;
                margin: 0 0 0 2.3rem;
                shape-outside: circle(50%);
                transform: translateY(-25px);
            }
            
            &__draw {
                display: block;
                width: 60%;
                margin: 0 auto;
                border-radius: 5px;
                transition: 2s;
                max-width: 300px;

                &__open {
                    display: none;
                }
            }
        }
        &__image:hover {
            opacity: 0.5;
        }
        &__about {
            font-size: .8em;
            font-weight: bold;
            text-align: center;
            color: #494949;
            transform: translateY(-1350%) translateX(55px) rotate(14deg);
            animation: flicker-1 5s linear infinite both;
            
            
            &__open {
                display: none;
            }
        }
        &__text {
            display: none;
            &__open {
                font-size: .9em;
                margin: 1rem;
                padding: 1rem;
                text-align: justify;
                background-color: #ffffff5f;
                box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                
                @media (min-width: 1024px) {
                    font-size: 1.1em;
                    margin: 1rem;
                    padding: 1rem;
                    text-align: justify;
                    background-color: #ffffff5f;
                    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                        -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                        -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                    width: 60%;
                  }
                }

            &__back {
                display: none;
    
                &__open {
                        padding: .8rem 1.3rem;
                        display: block;
                        background-color: #bdb026;
                        border: none;
                        color: white;
                        font-weight: bold;
                        border-radius: 5px;
                        margin: 1rem auto;
    
                        @media (min-width: 1024px) {
                            padding: .8rem 1.3rem;
                            background-color: #bdb026;
                            border: none;
                            color: white;
                            font-weight: bold;
                            border-radius: 5px;
                           
                        }
                    } 
            }               
        }

        &__icons {
            display: none;

            &__open {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                font-size: 2em;
                color: #67805c

                
            }
            &__open > * :first-child {
                margin: 1rem  1rem 0 1rem;
            }
        }

       }
    
       &__secondsection {
        
        &__contact {
            font-size: 1em;
            font-weight: bold;
            margin: 0 2.5rem; 
            padding: 1rem;
            background-color: #ffffffa4;
                box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
            border-radius: 5px;
            line-height: 1.4rem;


            &__open {
                display: none;
            }
            &__link {
                text-decoration: none;
                color: black;
                font-size: 1rem;

                
                &:hover {
                    text-decoration: underline;
                    color: #bdb026;
                    
                }
            }
        }
        &__cv {
            margin-bottom: 2rem;
            &__button {
                padding: .8rem 1.3rem;
                display: block;
                background-color: #bdb026;
                border: none;
                color: white;
                font-weight: bold;
                border-radius: 5px;
                margin: 1rem auto;
                margin-bottom: 2rem;

                    &:hover {
                        color: #bdb026;
                        background-color: #494949;

                    }
                

                &__open{
                    display: none;
                }

                
            }
            &__cvopen {
                width: 95%;
                left: 2%;
                position: absolute;
                top: 7%;
                max-width: 500px;
                
            }
            
            &__close {
                display: none;
            }

            &__closebutton {
                display: none;

                &__open {
                    display: block;
                    margin: 0 auto;
                    background-color: #bdb026;
                    color: white;
                    font-size: 1.2em;
                    font-weight: bold;
                    padding: .5rem;
                    border: none;
                    position: absolute;
                    bottom: 6%;
                    right: 20px;
                }
            }

            &__dlbutton {
                display: none;

                &__open {
                    display: block;
                    margin: 0 auto;
                    background-color: #bdb026;
                    color: white;
                    font-size: 1.2em;
                    font-weight: bold;
                    padding: .5rem;
                    border: none;
                    position: absolute;
                    bottom: 6%;
                    right: 75px;
                }
            }
                }

    }
    
   
}
       
        
        

/* Animations des lettres */
div span {opacity:0;}
.letter01 {animation:letters 3s ease 2000ms forwards;}
.letter02 {animation:letters 3s ease 2500ms forwards;}
.letter03 {animation:letters 3s ease 3000ms forwards;}
.letter04 {animation:letters 3s ease 3500ms forwards;}
.letter05 {animation:letters 3s ease 4000ms forwards;}
.letter07 {animation:letters 3s ease 4500ms forwards;}
.letter08 {animation:letters 3s ease 5000ms forwards;}
.letter09 {animation:letters 3s ease 5500ms forwards;}
.letter10 {animation:letters 3s ease 6000ms forwards;}
.letter11 {animation:letters 3s ease 6500ms forwards;}

@keyframes letters {
	0% {opacity:0;}
	100% {opacity:1.0;}
}

@keyframes flicker-1 {
    0%,
    100% {
      opacity: 1;
      color: #bdb026;
    }
    41.99% {
      opacity: 1;
      color: #494949;
    }
    42% {
      opacity: 0;
    }
    43% {
      opacity: 0;
    }
    43.01% {
      opacity: 1;
    }
    47.99% {
      opacity: 1;
    }
    48% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    49.01% {
      opacity: 1;
    }
  }

  .intro__desktop {
    @media (max-width: 1024px) {
        display: none;
    }
}

.introdt {
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
    
    &__left {

        &__title {
            font-size: 3em;
            font-weight: bold;
            color: #bdb026;
            text-align: center;
            margin-bottom: 1rem;
    
            &__open {
                 display: none;
            }
           }

           &__contact {
                font-weight: bold;
                background-color: #ffffffa4;
                    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                    -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                    -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                border-radius: 5px;
                margin-top: 2rem;
                line-height: 1.4em; 
                padding: 1rem; 

                &__element {
                    &:hover {
                        color: #bdb026;
                        
                        &:after {
                            color: white;
                            position: absolute;
                            padding: 1rem 2rem;
                            font-size: 1.1rem;
                            animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                                -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                            transform: translateX(20px) translateY(-15px);
                            background-color: #bdb026;
                                box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                                -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                                -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                            border-radius: 5px;
    
                        }
                        
                    }
                    &__tel:hover:after {
                        content: 'Parlons nous !';
                    }
                    &__link {
                        text-decoration: none;
                        color: black;
                        font-size: 1rem;
                        &:hover:after {
                            content: 'Ecrivez moi !';
                        }
                    }
                }

            &__open {
                display: none;
        }
        }

        &__cv {
            &__button {
                    padding: .8rem 1.3rem;
                    background-color: #bdb026;
                    border: none;
                    color: white;
                    font-weight: bold;
                    border-radius: 5px;
                    margin: 1rem;
            
                    &:hover {
                        color: #bdb026;
                        background-color: #494949;
                }

                &__open{
                    display: none;
                }
                
            }
            &__cvopen {
                    width: 90%;
                    max-width: 900px;
            }
            
            &__close {
                display: none;
            }

            &__buttons {
                display: flex;
                justify-content: flex-end;
                margin: .5rem 1rem;
                
                &__closebutton {
                    display: none;
    
                    &__open {
                        margin-right: .5rem;
                        background-color: #bdb026;
                        color: white;
                        font-size: 1.1em;
                        font-weight: bold;
                        padding: .3rem .5rem;
                        border: none;
                     
                    }
                }
    
                &__dlbutton {
                    display: none;
    
                    &__open {
                        margin-right: 1rem;
                        background-color: #bdb026;
                        color: white;
                        font-size: 1.1em;
                        font-weight: bold;
                        padding: .3rem .5rem;
                        border: none;
                       
                    }
                }

            }

                }

    }

    &__right {

        &__container {
            display: flex;
        }

        &__hidden {
            display: none;
        }

        &__image {

            &__draw {
                border-radius: 5px;
                transition: 2s;
                max-width: 400px;
                &__open {
                    display: none;   
                }    
            }
            &__me {
                display: none;
                &__open {
                    border-radius: 5px;
                    height: 400px;
                    max-height: 400px;
                    background-color: #ffffff5f;
                    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                        -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                        -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                }    
            }
                      
        }
        &__image:hover {
            opacity: 0.5;
        }
        &__about {
            font-size: 1.2em;
            font-weight: bold;
            text-align: center;
            color: #494949;
            transform: translateY(-1100%) translateX(100px) rotate(12deg);
            animation: flicker-1 5s linear infinite both;
                   
            &__open {
                display: none;
            }
        }

        &__text {
            display: none;

            &__open {
                font-size: 1.1em;
                margin: 1rem;
                padding: 1rem;
                text-align: justify;
                background-color: #ffffff5f;
                box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                    -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                    -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                height: 100%;
                }

            &__back {
                display: none;
    
                &__open {                       
                    padding: .7rem 0;
                    background-color: #bdb026;
                    border: none;
                    color: white;
                    font-weight: bold;
                    border-radius: 5px;
                    width: 100%;
                    margin-top: 10px;
                    } 
            }

            &__cvbutton {
                display: none;
    
                &__open {
                    padding: .8rem 1.3rem;
                    background-color: #bdb026;
                    border: none;
                    color: white;
                    font-weight: bold;
                    border-radius: 5px;
                    width: 110px;                    
                    } 
            }                        
        }
    }
    &__icons {
        display: none;

        &__open {
            display: flex;
            justify-content: flex-start;
            font-size: 2em;
            margin-top: 2rem;
            color: #67805c;

            &__icon {
                margin-right: .5rem;
                &:hover {
                    color: rgb(48, 48, 48);
                    &:after {
                        color: white;
                        padding: .3rem 1rem;
                        margin: auto .3rem;
                        font-size: 1rem;
                        animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                            -webkit-animation: tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                            background-color: #bdb026;
                            box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                            -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                            -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
                        border-radius: 5px;
                    }
                }
                &__js:hover:after {
                    content: 'JavaScript';
                }
                &__react:hover:after {
                    content: 'React';
                }
                &__redux:hover:after {
                    content: 'Redux';
                }
                &__node:hover:after {
                    content: 'NodeJS';
                }
                &__html:hover:after {
                    content: 'HTML 5';
                }
                &__css:hover:after {
                    content: 'CSS 3';
                }
                &__postgresql:hover:after {
                    content: 'PostgreSQL';
                }
                &__git:hover:after {
                    content: 'GitHub';
                }
                &__bootstrap:hover:after {
                    content: 'Bootstrap';
                }
            }
        } 
    }
    
}


/* ----------------------------------------------
 * Generated by Animista on 2021-10-2 22:24:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
@use 'reset';

@import 'semantic-ui-css/semantic.min.css';

//polices titres
@import url('https://fonts.googleapis.com/css2?family=Birthstone+Bounce:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');
// police générale
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

    height: 100vh;
    background-image: url('/images/background.png');
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @media (min-width: 1024px) {
      background-image: url('/images/background.png');
      background-size: 40%;
      background-position: 8vw;
      background-repeat: no-repeat;
      background-attachment: fixed;
      
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.exp {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #ffffffd5;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
       -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
       -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    
    @media (min-width: 1024px) {
        box-sizing: border-box;
        margin: .5rem;
        width: 46%;

    }

    &__image {
        width: 100%;
        margin-bottom: 1rem;
        &:hover {
            opacity: 0.5;
        }
    }

       &__title {
           font-size: 2em;
           font-weight: bold;
           line-height: 2.6rem;

       }

       &__date {
           text-align: right;
       }

       &__place {
           text-align: right;
       }

       &__subtitle {
           text-align: center;
           font-size: 1.8em;
           line-height: 2.8rem;

       }

       &__description {
           background-color: #bdb026b0;
           border-radius: 5px;
           padding: 1rem;
           text-align: justify;
       }

       &__link {
           display: block;
           text-decoration: none;
           color: #67805c;
           text-align: center;
           font-size: 1.1em;
           margin: .5rem;
           
           &:hover {
            color: white;
            background-color: #bdb026b0;
            margin: .5rem 2rem;
            border-radius: 10px;
           }
       }
}



.header {

    &__firstsection {
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      background-color: #f0f0f0;

      @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 99;
        background-color: #f0f0f000;
        height: 80px;
            
      }

        &__dropdown {
            background-color: #ffffff00;
            margin: 0 1rem;
            color: #bdb026;
            height: 3rem;
            //width: 3rem;
            border: none;

            @media (min-width: 1024px) {
                display: none;
                
              }

            &__element {
                border-top: 4px solid #bdb026;
                border-bottom: 4px solid #bdb026;
                height: .9rem;
                width: 1.8rem;
            }
            &__secondelement {
                border-bottom: 4px solid #bdb026;
                height: .6rem;
                width: 1.8rem;
            }
        }

        &__dropmenu {
            visibility: visible;
            background-color: #fefefe;
            position: absolute;
            z-index: 100;
            margin-left: 1rem;
            width: 70%;
            padding: .7rem 0;
            border-radius: 10px;

            &__close {
                visibility: hidden;
                position: absolute;
            }

            &__element {
                display: block;
                text-decoration: none;
                color: #bdb026;
                padding: .4rem 1.5rem;
                font-size: 1.2em;
                border: none;
            }
            // bien pour desktop, pas visible pour mobile: 
            &__element:hover {
                color: white;
                background-color: #bdb026;
                margin: 0 1rem;
                border-radius: 20px;
                text-align: right;
                border: none;
            }
        }

        &__dropdownelement {
            width: 60vw;
           
        }

        &__dropdownelement:hover {
            background-color: #bdb026 !important; 
        }

        &__social {
            margin-top: .5rem;
        }

        &__menudesktop {
            margin-top: 1.5rem;

            @media (max-width: 1024px) {
                display: none;
            }

            &__link {
                font-size: 1.3em;
                text-decoration: none;
                color: #bdb026;
                padding: 1.5rem;
                
                &:hover {
                    color: white;
                    background-color: #bdb026;
                    padding: 1.5rem;
                    font-weight: bold;
                    border-radius: 5px;
                }
            }

        }
    }

    &__secondsection {

        padding: 3.5em 1rem 1.5rem 1rem;
        
        @media (min-width: 1024px) {
            padding: .5em;
          }

        &__name {
            text-align: center;
            color: #67805c;
            font-size: 3em;
            font-family: 'Birthstone Bounce', cursive;

            @media (min-width: 1024px) {
                text-align: center;
                color: #67805c;
                font-size: 7em;
                font-family: 'Birthstone Bounce', cursive;
                line-height: 8rem;
              }
        }

        &__textanimation {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &__iam {
                margin-right: 5px;
                color: #222222;
                font-size: .5em;

                @media (min-width: 1024px) {
                    margin-right: 0;
                    color: #222222;
                    font-size: 1rem;
                    line-height: 3rem;

                    
                  }
            }
            &__designation {
                
                color: #222222;
                font-size: 1.1em;

                @media (min-width: 1024px) {
                    color: #222222;
                    font-size: 1.5em;
                    
                  }

            }
        }
}
}

  @import url("//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");

a.btn-social,
.btn-social
    {
      border-radius: 50%;
      color: #ffffff !important;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin: auto 5px;
      text-align: center;
      text-decoration: none;
      transition: background-color .3s;
        -webkit-transition: background-color .3s;

        @media (min-width: 1024px) {
            border-radius: 50%;
            color: #ffffff !important;
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin: auto 5px;
            text-align: center;
            text-decoration: none;
            transition: background-color .3s;
                -webkit-transition: background-color .3s;
            font-size: 1.2em;
            
          }
  }

.btn-social .fa,.btn-social i
    {
        backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -ms-transform: scale(1);
          -o-transform: scale(1);
        transform: scale(1);
        transition: all .25s;
          -webkit-backface-visibility: hidden;
          -webkit-transform: scale(1);
          -webkit-transition: all .25s;
    }
.btn-social:hover,.btn-social:focus
    {
        color: #fff;
        outline: none;
        text-decoration: none;
    }
.btn-social:hover .fa,.btn-social:focus .fa,.btn-social:hover i,.btn-social:focus i
    {
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
      transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
.btn-social.btn-xs
    {
        font-size: 9px;
        height: 24px;
        line-height: 13px;
        margin: 6px 2px;
        width: 24px;
    }
.btn-social.btn-sm
    {
        font-size: 13px;
        height: 36px;
        line-height: 18px;
        margin: 6px 2px;
        width: 36px;
    }
.btn-social.btn-lg
    {
        font-size: 22px;
        height: 72px;
        line-height: 40px;
        margin: 10px 6px;
        width: 72px;
    }



.btn-github
    {
        background-color: #6f6f6f;
    }
.btn-github:hover
    {
        background-color: #6f6f6f79;
    }
.btn-linkedin
    {
        background-color: #577758;
    }
.btn-linkedin:hover
    {
        background-color: #5777586b;
    }

.btn-email
    {
        background-color: #e0c23a;
    }
.btn-email:hover
    {
        background-color: #e0c13a7b;
    }


 
     

    
.experience {
    //background-color: #0c8a60;
    padding: 1rem;
    line-height: 1.3rem;

    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 80%;
        margin: 0 auto;
        box-sizing: border-box;


    }

}


.contact {
    cursor: pointer;
    text-align: center;
    @media (min-width: 1024px) {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;

  }

    &__text {
        display: block;
        margin: 2rem;
        background-color: #ffffff5f;
           box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
             -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
             -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
        padding: 1rem .5rem;

        @media (min-width: 1024px) {
          display: block;
          margin: 2rem 11rem;
          background-color: #ffffff5f;
             box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
               -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
               -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
          padding: 1rem .5rem;
  
      }
    }

    &__textarea {
        display: block;
        margin: 0 auto;
        border: 1px solid #bdb026b0;
        margin-bottom: 1rem;
        width: 70%;
        padding: .5rem;

    }

    &__submit {
        background-color: #bdb026;
        color: white;
        font-weight: bold;
        border: none;
        padding: 1rem;
        font-size: 1.1em;
        border-radius: 5px;
        display: block;
        margin: 2rem auto;
    }
    &__message {
        margin: 2rem auto;
        background-color: #bdb026b0;
           box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
             -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
             -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3);
        padding: 1rem;
        width: 70%;
        font-size: 1.1em;
    }

    &__image {
      width: 450px;
      height: 400px;
      @media (max-width: 1424px) {
        width: 250px;
        height: 200px;
    }
    @media (max-width: 1024px) {
       width: 230px;
       height: 190px;
       margin-bottom: 2rem;
  }
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #bdb026;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }


.formation {
    padding: 1rem;
    line-height: 1.3rem;


    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 80%;
        margin: 0 auto;
        box-sizing: border-box;


    }

    &__diploma {
        margin-bottom: 1rem;
        padding: 1rem;
        background-color: #ffffffd5;
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
           -webkit-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3); 
           -moz-box-shadow: 0 2px 9px rgba(0, 0, 0, 0.3);
        box-sizing: border-box; 

        @media (min-width: 1024px) {
            box-sizing: border-box;
            margin: .5rem;
            width: 46%;
    
        }
    
           &__title {
               font-size: 2em;
               font-weight: bold;
               line-height: 2.6rem;

           }
    
           &__date {
               text-align: right;
           }
    
           &__place {
               text-align: right;
           }
           
           &__description {
                background-color: #bdb026b0;
                border-radius: 5px;
                padding: 1rem;

                &__ul {
                   list-style: inside;
                }
        }
        &__subtitle {
            text-align: center;
            font-size: 1.7em;
            line-height: 2.7rem;

        }
    }

}


